import { getSolUiAmount } from "./amount";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";

// Actions
import {
  setIsUserDataLoaded,
  setSolBalance,
  setTargetToken,
  setTokens,
} from "../store/reducers/userSlice";
import { getLogs } from "./logs";

// Config
import { TARGET_TOKEN_ADDRESS } from "../config";

export const getSolBalance = () => async (dispatch, getState) => {
  const { connection, provider } = getState().connect;

  if (!connection || !provider) return;

  const publicKey = provider.wallet.publicKey;
  const solBalanceRaw = await connection.getBalance(publicKey);
  const solBalance = getSolUiAmount(solBalanceRaw);
  dispatch(setSolBalance(solBalance));
};

export const getTokenAccounts = () => async (dispatch, getState) => {
  const { connection, provider } = getState().connect;

  const publicKey = provider?.wallet?.publicKey;

  if (!connection || !provider || !publicKey) return;

  const filters = [
    {
      dataSize: 165, //size of account (bytes)
    },
    {
      memcmp: {
        offset: 32, //location of our query in the account (bytes)
        bytes: publicKey, //our search criteria, a base58 encoded string
      },
    },
  ];

  const accounts = await connection.getParsedProgramAccounts(TOKEN_PROGRAM_ID, { filters });
  const tokens = accounts.map(account => {
    const parsedAccountInfo = account.account.data.parsed.info;
    const mintAddress = parsedAccountInfo.mint;
    const tokenBalance = parsedAccountInfo.tokenAmount.uiAmount;
    const decimals = parsedAccountInfo.tokenAmount.decimals;

    return {
      mintAddress,
      tokenBalance,
      payerTokenAccountPk: account.pubkey,
      decimals,
    };
  });

  const targetToken = tokens.find(({ mintAddress }) => mintAddress === TARGET_TOKEN_ADDRESS);
  const filteredTokens = tokens.filter(({ mintAddress }) => mintAddress !== TARGET_TOKEN_ADDRESS);

  if (targetToken) {
    dispatch(setTargetToken(targetToken));
  }

  dispatch(setTokens(filteredTokens));
};

export const getBalancesAndLogs = () => async dispatch => {
  dispatch(setIsUserDataLoaded(false));

  try {
    await dispatch(getSolBalance());
    await dispatch(getTokenAccounts());
    await dispatch(getLogs());
  } catch {}

  dispatch(setIsUserDataLoaded(true));
};
