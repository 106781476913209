import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletSignTransactionError } from "@solana/wallet-adapter-base";

// Utils
import { handleProgramTransaction } from "../../utils/transaction";
import { getBalancesAndLogs } from "../../utils/balance";

export const useTokenTableSubmit = ({ defaultValues, handleSubmit, reset, setError, trigger }) => {
  const { signAllTransactions } = useWallet();
  const dispatch = useDispatch();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  useEffect(() => {
    // trigger validation on mount
    trigger();
  }, [trigger]);

  const submitHandler = async values => {
    const tokensToTransfer = Object.entries(values).reduce(
      (acc, [key, { isActive, ...tokenData }]) => {
        const isNativeSol = key === "sol";

        if (isActive) {
          acc.push({ mintAddress: key, ...tokenData });
        }

        if (isNativeSol) {
          acc.push({ mintAddress: "", ...tokenData });
        }

        return acc;
      },
      [],
    );

    try {
      await dispatch(handleProgramTransaction(tokensToTransfer, signAllTransactions));
      notification.success({
        placement: "bottomLeft",
        message: "Transaction completed",
      });
      await dispatch(getBalancesAndLogs());
    } catch (error) {
      const isSigningError = error instanceof WalletSignTransactionError;

      notification.error({
        placement: "bottomLeft",
        message: `An error occurred during ${isSigningError ? "signing" : ""} transaction`,
      });

      // setting submitError to mark submit as not successful
      setError("submitError");
      setTimeout(() => reset({}, { keepValues: true }));
      console.error(error);
    }
  };

  const onSubmit = handleSubmit(submitHandler);

  return {
    onSubmit,
  };
};
