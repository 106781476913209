import { useWallet } from "@solana/wallet-adapter-react";
import { useSelector } from "react-redux";
import styles from "./BalanceBlock.module.scss";

const BalanceBlock = () => {
  const { connected } = useWallet();
  const solBalance = useSelector(s => s.user.solBalance);

  if (!connected) return null;

  return (
    <div className={styles.container}>
      <p>Balance: {solBalance} SOL</p>
    </div>
  );
};

export default BalanceBlock;
