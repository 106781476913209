import { Controller, useFormContext } from "react-hook-form";
import { Checkbox as AntCheckbox } from "antd";

const Checkbox = ({ name, className, onChange, ...restProps }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange: onChangeController, onBlur, value, ref } }) => {
        return (
          <AntCheckbox
            ref={ref}
            checked={value}
            className={className}
            onChange={e => {
              onChangeController(e);
              onChange?.(e);
            }}
            onBlur={onBlur}
            {...restProps}
          />
        );
      }}
    />
  );
};

export default Checkbox;
