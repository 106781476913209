import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";

// Components
import TokensTableRow from "./TokensTableRow/TokensTableRow";
import SolTableRow from "./SolTableRow/SolTableRow";
import ButtonsBlock from "./ButtonsBlock/ButtonsBlock";

// Hooks
import { useTokenTableSubmit } from "./useTokenTableSubmit";

// Utils
import { createYupSchema, TOKENS_TABLE_FIELDS } from "./validationSchema";

// Configs
import { SOL_MAX_DELTA } from "../../config";

// Styles
import styles from "./TokensTable.module.scss";

const TokensTable = ({ tokens, solBalance }) => {
  const formattedMaxAmount = Number((solBalance - SOL_MAX_DELTA).toFixed(9));
  const maximumSolAmount = formattedMaxAmount >= 0 ? formattedMaxAmount : 0;
  const defaultValues = useMemo(
    () =>
      tokens.reduce(
        (acc, { mintAddress, tokenBalance }) => {
          acc[mintAddress] = {
            [TOKENS_TABLE_FIELDS.amount]: tokenBalance,
            [TOKENS_TABLE_FIELDS.checkbox]: true,
          };

          return acc;
        },
        {
          sol: {
            [TOKENS_TABLE_FIELDS.amount]: maximumSolAmount,
          },
        },
      ),
    [maximumSolAmount, tokens],
  );

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(createYupSchema(tokens)),
    context: { tokens, solBalance: maximumSolAmount },
    mode: "all",
  });

  const { handleSubmit, reset, setError, trigger } = methods;
  const { onSubmit } = useTokenTableSubmit({
    reset,
    defaultValues,
    handleSubmit,
    setError,
    trigger,
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit} className={styles.tableContainer}>
        <div className={styles.tokenListContainer}>
          <SolTableRow />
          {tokens.map(tokenData => (
            <TokensTableRow key={tokenData.mintAddress} {...tokenData} />
          ))}
        </div>
        <ButtonsBlock defaultValues={defaultValues} />
      </form>
    </FormProvider>
  );
};

export default TokensTable;
