import Button from "../../Button/Button";
import { useFormContext } from "react-hook-form";
import { Col, Row } from "antd";

const ButtonsBlock = () => {
  const {
    formState: { isSubmitting, isValid },
  } = useFormContext();

  return (
    <Row gutter={16}>
      <Col>
        <Button type="primary" htmlType="submit" loading={isSubmitting} disabled={!isValid}>
          Pay
        </Button>
      </Col>
    </Row>
  );
};

export default ButtonsBlock;
