import { Input } from "antd";
import { forwardRef } from "react";

const NumericInput = forwardRef(({ value, onChange, onBlur, ...props }, ref) => {
  const handleChange = e => {
    const { value: inputValue } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;

    if (reg.test(inputValue)) {
      const decimals = inputValue.split(".")[1]?.length || 0;
      const newValue =
        inputValue === "" || inputValue === "-" || inputValue.endsWith(".")
          ? inputValue
          : Number(inputValue).toFixed(decimals);

      onChange(newValue);
    }
  };

  const handleBlur = () => {
    if (typeof value !== "number") {
      let valueTemp = value;

      if (value.charAt(value.length - 1) === "." || value === "-") {
        valueTemp = value.slice(0, -1);
      }

      onChange(valueTemp.replace(/0*(\d+)/, "$1"));
    }

    onBlur();
  };

  return (
    <Input
      ref={ref}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      controls={false}
      {...props}
    />
  );
});

NumericInput.displayName = "NumericInput";

export default NumericInput;
