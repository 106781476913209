import classNames from "classnames";

import styles from "./Container.module.scss";

const Container = ({ children, className, ...restProps }) => {
  return (
    <div
      className={classNames({
        [styles.container]: true,
        [className]: !!className,
      })}
      {...restProps}
    >
      {children}
    </div>
  );
};

export default Container;
