import { Col, Row, Tooltip } from "antd";
import { useFormContext } from "react-hook-form";

// Components
import Input from "../../Input/Input";
import Checkbox from "../../Checkbox/Checkbox";
import ErrorMessage from "../../Input/ErrorMessage/ErrorMessage";

import styles from "../TokensTable.module.scss";

// Utils
import { getTokenNameFromMint } from "../../../utils/common";

// Config
import { TOKENS_TABLE_FIELDS } from "../validationSchema";

const TokensTableRow = ({ mintAddress }) => {
  const tokenName = getTokenNameFromMint(mintAddress);
  const {
    trigger,
    formState: { errors },
  } = useFormContext();

  const onCheckboxChange = () => {
    trigger(`${mintAddress}.${TOKENS_TABLE_FIELDS.amount}`);
  };

  return (
    <>
      <Row className={styles.rowContainer} gutter={16}>
        <Col span={6}>
          <Tooltip title={mintAddress}>
            <p className={styles.tokenName}>{tokenName}</p>
          </Tooltip>
        </Col>
        <Col span={17}>
          <Input
            name={`${mintAddress}.${TOKENS_TABLE_FIELDS.amount}`}
            placeholder="Enter amount"
            size="large"
            type="number"
          />
        </Col>
        <Col span={1}>
          <Checkbox
            name={`${mintAddress}.${TOKENS_TABLE_FIELDS.checkbox}`}
            onChange={onCheckboxChange}
          />
        </Col>
      </Row>
      <Row>
        <Col span={17} offset={6}>
          <ErrorMessage
            errors={errors}
            name={`${mintAddress}.${TOKENS_TABLE_FIELDS.amount}`}
            className={styles.errorMessage}
          />
        </Col>
      </Row>
    </>
  );
};

export default TokensTableRow;
