import { useFormContext, Controller } from "react-hook-form";
import { Input as AntInput } from "antd";
import NumericInput from "./NumericInput/NumericInput";

// Input (ant) connected to form Provider
const Input = ({ name, className, placeholder, type, ...props }) => {
  const { control } = useFormContext();

  const InputComponent = type === "number" ? NumericInput : AntInput;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        return (
          <InputComponent
            ref={ref}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            className={className}
            placeholder={placeholder}
            {...props}
          />
        );
      }}
    />
  );
};

export default Input;
