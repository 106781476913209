import { useSelector } from "react-redux";
import { useWallet } from "@solana/wallet-adapter-react";

// Components
import { Result } from "antd";
import Container from "../../components/Container/Container";
import TokensTable from "../../components/TokensTable/TokensTable";
import Loader from "../../components/Loader/Loader";
import LogsTable from "../../components/LogsTable/LogsTable";

// Config
import { TARGET_TOKEN_ADDRESS } from "../../config";

const Home = () => {
  const tokens = useSelector(s => s.user.tokens);
  const solBalance = useSelector(s => s.user.solBalance);
  const targetToken = useSelector(s => s.user.targetToken);
  const isUserDataLoaded = useSelector(s => s.user.isLoaded);
  const { connected, connecting, disconnecting } = useWallet();

  const isValidTargenTokenBalance = targetToken.tokenBalance > 0;

  if (connecting || (connected && !disconnecting && !isUserDataLoaded)) return <Loader />;

  if (!connected || !isUserDataLoaded) return null;

  return (
    <Container>
      {isValidTargenTokenBalance ? (
        <TokensTable tokens={tokens} solBalance={solBalance} />
      ) : (
        <Result
          status="warning"
          title={`You have not enough amount of Target Token (${TARGET_TOKEN_ADDRESS})`}
        />
      )}
      <LogsTable />
    </Container>
  );
};

export default Home;
