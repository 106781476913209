import { useSelector } from "react-redux";
import { Table } from "antd";
import styles from "./LogsTable.module.scss";

const columns = [
  {
    title: "Date",
    dataIndex: "dateString",
  },
  {
    title: "Operation code",
    dataIndex: "operationCode",
  },
  {
    title: "Amount",
    dataIndex: "amount",
  },
  {
    title: "Payer",
    dataIndex: "payer",
  },
  {
    title: "Token",
    dataIndex: "token",
  },
];

const LogsTable = () => {
  const logs = useSelector(s => s.user.logs);

  if (!logs.length) return null;

  const logsDataSource = logs.map((log, i) => ({ ...log, key: i }));

  return (
    <div>
      <h2 className={styles.title}>LOGS</h2>
      <Table
        dataSource={logsDataSource}
        columns={columns}
        pagination={{ hideOnSinglePage: true }}
      />
    </div>
  );
};

export default LogsTable;
