import "@solana/wallet-adapter-react-ui/styles.css";
import "./styles/globals.scss";

// Components
import Layout from "./components/Layout/Layout";
import Home from "./pages/Home/Home";

// Hooks
import { useInitialization } from "./hooks/useInitialization";

const App = () => {
  useInitialization();

  return (
    <div className="App">
      <Layout>
        <Home />
      </Layout>
    </div>
  );
};

export default App;
