import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

import Container from "../Container/Container";
import BalanceBlock from "../BalanceBlock/BalanceBlock";
import styles from "./Header.module.scss";

const Header = () => {
  return (
    <Container>
      <header className={styles.header}>
        <h1 className={styles.title}>SOLANA</h1>
        <div className={styles.connectionBlock}>
          <BalanceBlock />
          <WalletMultiButton className={styles.connectButton} />
        </div>
      </header>
    </Container>
  );
};

export default Header;
