import { Col, Row } from "antd";

// Components
import Input from "../../Input/Input";
import ErrorMessage from "../../Input/ErrorMessage/ErrorMessage";

// Config
import { TOKENS_TABLE_FIELDS } from "../validationSchema";

// Styles
import styles from "../TokensTable.module.scss";
import { useFormContext } from "react-hook-form";

const SolTableRow = () => {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Row className={styles.rowContainer} gutter={16}>
        <Col span={6}>
          <p className={styles.tokenName}>SOL</p>
        </Col>
        <Col span={17}>
          <Input
            name={`sol.${TOKENS_TABLE_FIELDS.amount}`}
            placeholder="Enter SOL amount"
            size="large"
            type="number"
          />
        </Col>
        <Col span={1} />
      </Row>
      <Row>
        <Col span={17} offset={6}>
          <ErrorMessage
            errors={errors}
            name={`sol.${TOKENS_TABLE_FIELDS.amount}`}
            className={styles.errorMessage}
          />
        </Col>
      </Row>
    </>
  );
};

export default SolTableRow;
