import { AnchorProvider } from "@project-serum/anchor";
import { Connection } from "@solana/web3.js";

// Actions
import { setConnection, setProvider } from "../store/reducers/connectSlice";

// Config
import { COMMITMENT, NETWORK_ENDPOINT } from "../config";

export const getInitialConnection = wallet => (dispatch, getState) => {
  const opts = {
    preflightCommitment: COMMITMENT,
  };
  const stateConnection = getState().connect.connection;
  const connection = stateConnection || new Connection(NETWORK_ENDPOINT, opts.preflightCommitment);

  if (!stateConnection) {
    dispatch(setConnection(connection));
  }

  const provider = new AnchorProvider(connection, wallet, opts);

  dispatch(setProvider(provider));
};
